html {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  font-size: 12px;
}

body {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  font-family: proxima-nova;
  font-weight: 400;
}

@font-face {
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: proxima-nova, sans-serif;
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: proxima-nova, sans-serif;
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: proxima-nova-condensed, sans-serif;
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: proxima-nova-condensed, sans-serif;
  font-weight: 700;
  font-style: normal;
}
