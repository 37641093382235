.Main {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
}

.Main .Content {
  width: 50%;
  box-sizing: border-box;
  /* padding: 8.33%; */
  padding: 40px;
  padding-bottom: 64px;
  padding-left: 4.15%;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  box-shadow: 0px 2px 4px 0px rgba(96, 97, 112, 0.16);
  z-index: 4;
  background: white;
}

.Main .Content .header {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.Main .Content .header .logo {
}

.Main .Content .header .logo:hover {
  cursor: pointer;
}

.Main .Content .header .logo img {
  height: 48px;
}

.Main .Content .body {
  flex: 1;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.Main .Content .body .hero {
  margin-top: auto;
}

.Main .Content .body .hero .primary {
  font-size: 36px;
  font-family: proxima-nova-condensed;
  font-weight: 900;
  line-height: 1.2em;
}

.Main .Content .body .hero .secondary {
  font-size: 16px;
  font-family: proxima-nova;
  font-weight: 400;
  line-height: 1.2em;
  margin-top: 16px;
}

.Main .Content .body .hero .secondary .thing-container {
  background: rgba(255, 255, 255, 1);
  font-weight: 700;
  color: rgb(47, 76, 161);
  display: inline-block;
  position: relative;
}
.Main .Content .body .hero .secondary .thing-container .text {
  background: rgba(255, 255, 255, 1);
  z-index: 2;
}

.Main .Content .body .hero .secondary .thing-container:hover {
  cursor: pointer;
  text-decoration: underline;
}

.Main .Content .body .hero .secondary .thing-container img {
  position: absolute;
  left: 25%;
  top: 10%;
  height: 16px;
  z-index: 1;
  pointer-events: none;
  opacity: 0;
  transition: all 0.2s ease-out;
}

.Main .Content .body .hero .secondary .thing-container:hover img.thing-1 {
  top: -18px;
  left: -20px;
  opacity: 1;
}

.Main .Content .body .hero .secondary .thing-container:hover img.thing-2 {
  top: -25px;
  left: 4px;
  opacity: 1;
}

.Main .Content .body .hero .secondary .thing-container:hover img.thing-3 {
  top: -19px;
  left: 27px;
  opacity: 1;
}

.Main .Content .body .hero .secondary .thing-container:hover img.thing-4 {
  top: -12px;
  right: -23px;
  left: auto;
  opacity: 1;
}

.Main .Content .body .hero .secondary .thing-container:hover img.thing-5 {
  top: 11px;
  right: -21px;
  left: auto;
  opacity: 1;
}

.Main .Content .body .hero .secondary .thing-container:hover img.thing-6 {
  top: 36px;
  right: -6px;
  left: auto;
  opacity: 1;
}

.Main .Content .body .hero .secondary .thing-container:hover img.thing-7 {
  top: 32px;
  left: -1px;
  opacity: 1;
}

.Main .Content .body .hero .secondary .thing-container:hover img.thing-8 {
  top: 22px;
  left: -27px;
  opacity: 1;
}

.Main .Content .body .hero .row {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  padding-top: 32px;
}

.Main .Content .body .hero .row .button {
  height: 64px;
  background: #3a66bf;
  width: 240px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  /* margin-top: 32px; */
  flex-shrink: 0;
  margin-right: 48px;
  font-family: proxima-nova-condensed;
  font-weight: 700;
  color: white;
}

.Main .Ca {
  width: 50%;
  background: rgba(47, 76, 160, 0.01);
  position: relative;
  box-sizing: border-box;
  padding-bottom: 48px;
  z-index: 1;
}

.Main .contact-form-wrapper {
  /* height: 48px; */
  height: 100%;
  background: rgba(47, 76, 160, 1);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0px 0.5px 2px 0px rgba(96, 97, 112, 0.16);
  font-family: proxima-nova;

  font-weight: 700;
  color: white;
  transform: translateY(calc(100% - 48px));
  transition: all 0.2s ease-out;
  will-change: transform;
  z-index: 3;
}

.Main .contact-form-wrapper .head {
  height: 48px;
  width: 100%;
  box-sizing: border-box;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  letter-spacing: 0.2px;
}

.Main .contact-form-wrapper .head:hover {
  cursor: pointer;
}

.Main .contact-form-wrapper .head .text {
  margin-right: auto;
}

.Main .contact-form-wrapper .head:hover .text {
  text-decoration: underline;
}

.Main .contact-form-wrapper .head .icon {
  height: 16px;
  width: 16px;
}

.Main .contact-form-wrapper .head .icon img {
  height: 16px;
}

.Main .Ca .automaton-wrapper {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
}

#automaton-canvas {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
}

.Main .contact-form-wrapper.open {
  transform: translateY(calc(100% - 480px));
}

.Main .careers-button {
  background: rgba(47, 76, 160, 1);
  color: white;
  font-family: proxima-nova;
  font-weight: 700;
  color: white;

  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  position: absolute;
  top: 40px;
  right: -4px;
  height: 36px;
  padding-left: 16px;
  padding-right: 24px;
  letter-spacing: 0.2px;
  box-shadow: 0px 0.5px 2px 0px rgba(96, 97, 112, 0.16);
}

.Main .careers-button:hover {
  cursor: pointer;
}

.Main .careers-button img {
  margin-right: 16px;
}

.Main .careers-container {
  /* height: 200px; */
  position: absolute;
  top: 40px;
  right: -4px;
  border-radius: 4px;
  background: rgba(47, 76, 160, 1);
  padding: 24px;
  color: white;
  font-size: 14px;
  width: 50%;
  box-shadow: 0px 0.5px 2px 0px rgba(96, 97, 112, 0.16);
  transform: translateX(100%);
  transition: all 0.2s ease-out;
}

.Main .careers-container .hide {
  height: 24px;
  width: 24px;
  background: rgba(47, 76, 160, 1);
  position: absolute;
  left: 0px;
  top: 0;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
}

.Main .careers-container.open .hide {
  left: -20px;
}

.Main .careers-container .hide img {
  height: 14px;
  width: 14px;
}

.Main .careers-container .hide:hover {
  cursor: pointer;
}

.Main .careers-container.open {
  transform: translateX(0);
}

.Main .careers-container .primary-text {
  font-weight: 700;
  margin-bottom: 4px;
}

.Main .careers-container .secondary-text {
  font-weight: 400;
}

.Main .careers-container .secondary-text .green {
  color: #38ef7d;
  font-weight: 700;
}

.Main .Content .body .about {
  margin-top: 48px;
  padding-right: 20%;
  font-size: 14px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  box-sizing: border-box;
}

.Main .Content .body .about .h3 {
  font-size: 24px;
  font-weight: 700;
}

.Main .Content .body .about .subheading {
  margin-top: 8px;
  font-weight: 300;
}

.Main .Content .body .about .text-group {
  margin-top: 24px;
}

.Main .Content .body .about .lead-text {
  font-weight: 700;
  /* letter-spacing: 0.2px; */
  margin-bottom: 8px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
}

.Main .Content .body .about .lead-text .icon {
  margin-right: 8px;
  height: 16px;
  width: 16px;
}

.Main .Content .body .about .body-copy {
  font-weight: 300;
}

.Main .Content .body .about .body-copy .link {
  font-weight: 700;
  color: rgb(47, 76, 161);
}

.Main .Content .body .about .body-copy .link:hover {
  cursor: pointer;
  text-decoration: underline;
}

.show-mobile {
  display: none;
}

@media screen and (max-width: 480px) {
  .hide-mobile {
    display: none !important;
  }

  .Main .Ca {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    overflow: hidden;
  }

  .Main .Content {
    width: 100%;
    padding: 0px;
    padding-bottom: 0px;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+79,ffffff+100&1+0,1+79,0.95+100 */
    background: -moz-linear-gradient(
      45deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 79%,
      rgba(255, 255, 255, 0.95) 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      45deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 79%,
      rgba(255, 255, 255, 0.95) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      45deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 79%,
      rgba(255, 255, 255, 0.95) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f2ffffff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  }

  .Main .Content .header {
    padding: 24px;
  }

  .Main .Content .header .logo img {
    height: 32px;
    width: auto;
  }

  .Main .Content .body {
    overflow: auto;
  }

  .Main .Content .body .hero {
    padding-bottom: 64px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .Main .contact-form-wrapper {
    z-index: 5;
  }

  .Main .contact-form-wrapper.open {
    transform: translateY(calc(100% - 500px));
  }

  .Main .Content .body .hero {
    padding-bottom: 80px;
  }

  .Main .contact-form-wrapper .head {
    padding-left: 24px;
    padding-right: 24px;
  }

  .Main .contact-form-wrapper.show-mobile {
    display: block;
  }

  .Main .careers-button.show-mobile {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
  }

  .Main .careers-button {
    position: absolute;
    top: 24px;
    /* right: 0; */
    z-index: 4;
  }

  .Main .Content .body .about {
    margin-top: 0px;
    padding-right: 0;
    padding: 24px;
    padding-bottom: 80px;
    display: block;
    height: auto;
    /* padding-top: 0; */
  }

  .Main .careers-container.show-mobile {
    z-index: 5;
    display: block;
    top: 24px;
  }

  .Main .Content .body .hero .primary {
    font-size: 24px;
  }

  .Main .Content .body .hero .secondary {
    font-size: 14px;
  }

  /* .Main .Ca {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    overflow: hidden;
  }

  .Main .Content {
    width: 95%;
    padding: 32px;
    padding-bottom: 0px;
    margin-left: auto;
  }

  .Main .Content .header .logo img {
    height: 32px;
  }

  .Main .Content .body {
    overflow: auto;
  }

  .Main .Content .body .hero {
    padding-bottom: 64px;
  }

  .Main .Content .body .about {
    margin-top: 64px;
    display: block;
    padding-right: 0;
    margin-top: 64px;
    height: auto;
    padding-bottom: 64px;
  } */
}
