.form-submitted {
  padding: 36px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 50%;
}

.form-submitted .primary-text {
  font-size: 24px;
  font-family: proxima-nova;
  font-weight: 700;
  margin-bottom: 8px;
  margin-top: 24px;
}

.form-submitted .secondary-text {
  font-size: 12px;
  font-family: proxima-nova;
  font-weight: 400;
  line-height: 1.2em;
}

.form-submitted .close {
  position: absolute;
  right: 36px;
  top: 36px;
  height: 24px;
  width: 24px;
}

.form-submitted .close img {
  height: 100%;
  width: 100%;
}

.form-submitted .close:hover {
  cursor: pointer;
}

.contact-form {
  padding: 36px;
  position: relative;
  z-index: 1;
}

.contact-form .close {
  position: absolute;
  right: 36px;
  top: 36px;
  height: 24px;
  width: 24px;
}

.contact-form .close img {
  height: 100%;
  width: 100%;
}

.contact-form .close:hover {
  cursor: pointer;
}

.contact-form .primary-text {
  font-size: 24px;
  font-family: proxima-nova;
  font-weight: 700;
  margin-bottom: 8px;
  /* line-height: 1.2em; */
}

.contact-form .secondary-text {
  font-size: 12px;
  font-family: proxima-nova;
  font-weight: 400;
  line-height: 1.2em;
  /* margin-bottom: 4px; */
  /* margin-top: 8px; */
  /* margin-top: 16px; */
}

.contact-form .row {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.contact-form .input-group {
  /* margin-top: 24px; */
  position: relative;
  margin-top: 24px;
}

.contact-form .row .input-group {
  width: 50%;
  background: white;
  border-radius: 4px;
}

.contact-form .row .input-group:first-child {
  margin-right: 12px;
}

.contact-form .row .input-group:last-child {
  margin-left: 12px;
}

.contact-form .input-group input {
  height: 36px;
  padding: 0;
  border: none;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  padding-left: 12px;
  outline: none;
  background: none;
  font-family: proxima-nova;
  font-weight: 400;
  /* z-index: -2; */
}

.contact-form .input-group label {
  position: absolute;
  color: grey;
  left: 12px;
  top: 11px;
  font-weight: 400;
  pointer-events: none;
  /* z-index: -1; */
}

.contact-form .input-group input[required]:invalid + label {
  display: inline-block;
}

.contact-form .input-group input[required]:valid + label {
  display: none;
}

.contact-form .input-group input[required] + label:after {
  content: " *";
  color: red;
}

.contact-form .input-group textarea {
  width: 100%;
  box-sizing: border-box;
  height: 150px;
  padding: 12px;
  border-radius: 4px;
  outline: none;
  font-family: proxima-nova;
  border: none;
}

.contact-form button {
  border: none;
  box-sizing: border-box;
  height: 40px;
  padding-left: 32px;
  padding-right: 32px;
  border-radius: 4px;
  color: white;
  margin-top: 24px;
  background: #38ef7d;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 1px;
}

.contact-form button:hover {
  cursor: pointer;
}

.contact-form .error {
  color: #ff3b3b;
  margin-left: 16px;
}
